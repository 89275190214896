<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                                <b-row>
                                    <b-col md="6" sm="12">
                                    <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="circular_memo_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="circular_memo_no"
                                            v-model="disciplinaryAction.circular_memo_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @change="getCircularPublicationInfo"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.fiscal_year')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.fiscal_year_id"
                                                :options="fiscalYearList"
                                                id="fiscal_year_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                                        <ValidationProvider name="Organization"  vid="org_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.organization')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Office Type" vid="office_type_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_type_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.office_type')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Office" vid="office_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.office')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.office_id"
                                                :options="officeList"
                                                id="office_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Training Type"  vid="training_type_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="training_type_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.training_type')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.training_type_id"
                                                :options="trainingTypeList"
                                                id="training_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Training Category" vid="training_category_id">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="training_category_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.training_category')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.training_category_id"
                                                :options="trainingCategoryList"
                                                id="training_category_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Training Title" vid="training_title_id">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="training_title_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_config.training_title')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="disciplinaryAction.training_title_id"
                                                :options="trainingTitleList"
                                                id="training_title_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Mobile" vid='mobile' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mobile"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    id="mobile"
                                                    v-model="disciplinaryAction.mobile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    @change="getPersonalInfo"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider name="Name" vid='trainee_or_trainer_id' rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="trainee_or_trainer_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('elearning_tim.trainee_triner_name')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    disabled
                                                    v-model="disciplinaryAction.trainee_or_trainer_id"
                                                    :options="findUser"
                                                    id="trainee_or_trainer_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <ValidationProvider name="Disciplinary Action (En)"  vid="disciplinary_action" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="disciplinary_action"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_tim.disciplinary_action_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="disciplinary_action"
                                        v-model="disciplinaryAction.disciplinary_action"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Disciplinary Action (Bn)"  vid="disciplinary_action_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="disciplinary_action_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_tim.disciplinary_action_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="disciplinary_action_bn"
                                        v-model="disciplinaryAction.disciplinary_action_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Signature" vid="signature" ref="signature" :rules="`${id ? '' : 'required'}`">
                            <b-form-group class="row" label-cols-sm="3" label-for="signature" slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{$t('research_manage.attachment')}}<span class="text-danger"> *</span>
                                <!-- <v-slot v-if="main_form.signature !== null">
                                    <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.signature" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                </v-slot> -->
                                </template>
                                <b-form-file
                                    id="signature"
                                    @change="onSignatureChange"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                                <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { disciplinaryActionStore, disciplinaryActionUpdate, trainingApplicationPersonalInfo, trainingApplicationCircularPublicationInfo } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getdisciplinaryAction()
      this.disciplinaryAction = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      tmpSignature: '',
      formData: [],
      disciplinaryAction: {
        id: '',
        signature: [],
        circular_memo_no: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        mobile: '',
        trainee_or_trainer_id: '',
        disciplinary_action: '',
        disciplinary_action_bn: ''
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    officeTypeList: function () {
      return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    trainingCategoryList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
    },
    trainingTitleList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
    }
  },
  watch: {
  },
  methods: {
    async onSignatureChange (e) {
      const { valid } = await this.$refs.signature.validate(e)
      if (!valid) return false
      this.tmpSignature = e.target.files[0]
      this.sig_src = URL.createObjectURL(e.target.files[0])
    },
    getdisciplinaryAction () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getPersonalInfo () {
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationPersonalInfo, { mobile: this.disciplinaryAction.mobile })
      if (result.success) {
        this.disciplinaryAction.trainee_or_trainer_id = result.data.user_id
        this.disciplinaryAction = Object.assign({}, this.disciplinaryAction, {
          trainee_or_trainer_id: result.data.user_id
        })
      } else {
        this.disciplinaryAction.trainee_or_trainer_id = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.personalInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
    },
    async getCircularPublicationInfo () {
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationCircularPublicationInfo, { circular_memo_no: this.disciplinaryAction.circular_memo_no })
      if (result.success) {
        this.disciplinaryAction.fiscal_year_id = result.data.fiscal_year_id
        this.disciplinaryAction.training_type_id = result.data.training_type_id
        this.disciplinaryAction.training_category_id = result.data.training_category_id
        this.disciplinaryAction.training_title_id = result.data.training_title_id
        this.disciplinaryAction.org_id = result.data.org_id
        this.disciplinaryAction.office_type_id = result.data.office_type_id
        this.disciplinaryAction.office_id = result.data.office_id
      } else {
        this.disciplinaryAction.fiscal_year_id = ''
        this.disciplinaryAction.training_type_id = ''
        this.disciplinaryAction.training_category_id = ''
        this.disciplinaryAction.training_title_id = ''
        this.disciplinaryAction.org_id = ''
        this.disciplinaryAction.office_type_id = ''
        this.disciplinaryAction.office_id = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.circularInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
    },
    async createData () {
      this.formData = new FormData()
      Object.keys(this.disciplinaryAction).map(key => {
        //   if (key === 'signature') {
        //         this.formData.append(key, this.tmpSignature)
        //   } else {
          this.formData.append(key, this.disciplinaryAction[key])
        //   }
        })
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.disciplinaryAction.id) {
        this.formData.append('signature', this.tmpSignature)
        this.formData.append('id', this.disciplinaryAction.id)
        result = await RestApi.postData(trainingElearningServiceBaseUrl, disciplinaryActionUpdate, this.formData)
      } else {
        this.formData.append('signature', this.tmpSignature)
        result = await RestApi.postData(trainingElearningServiceBaseUrl, disciplinaryActionStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
